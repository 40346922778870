import useBundleTranslation from 'i18n';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setBreadcrumbs } from 'store/breadcrumbsSlice';
import { ElementType } from 'components/element-viewer/index';
import { ElementTypeNS } from 'tools/elementType';
import getElementTypeLabel = ElementTypeNS.getElementTypeLabel;
import { setPageTitle } from 'store/pageTitleSlice';

export default function usePageTitleCrumbs(elementInfo: ElementType) {
    const { t } = useBundleTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const categoryName =
            elementInfo?.info.categoryName.length > 0 ? elementInfo.info.categoryName : t('uncategorized');

        dispatch(
            setBreadcrumbs([
                {
                    label: categoryName,
                    href: `/home/#/tile/category/${elementInfo?.info.categoryId}`,
                    translate: false,
                },
                {
                    label: elementInfo?.row.name,
                    translate: false,
                },
            ])
        );
        dispatch(setPageTitle(elementInfo?.row.name));
    }, [elementInfo?.row?.elementId]);
}
